<template>
  <div>
    <CalendarOv />

    <VacationPlanEmployee />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VacationPlanEmployee from '@/components/VacationPlanEmployee.vue';
import CalendarOv from '../components/CalendarOv.vue'

export default {
  components: {
    VacationPlanEmployee,
    CalendarOv,
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
}

</script>
