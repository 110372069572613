<template>
  <div class="VacSection">
    <div>
      <div style="width: 100%; ">
        <b-form-group
          id="input-group-8"
          :label="$t('EditY')"
          label-for="input-25"
          style="width: 150px; margin-bottom: 20px; width: 11%;"
        >
          <vue-select
            id="input-5"
            v-model="year"
            required
            :clearable="false"
            style="
              margin-right: 10px;
              color: #82868c;
              border-radius: 8px;
              border: none;
              box-shadow: none;
              width: 200px"
            :options="yearsArray"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
            @input="handleInput"
          />
        </b-form-group>
        <div>
          <ul style="padding: 0; border-top: 1px solid lightgray">
            <li
              v-for="store in getTeamsNames"
              :key="store.teamNameId"
              class="pm-li"
            >
              <div style="display: flex; justify-content: space-between; align-items:flex-end">
                <h5 style="color:#262E6C; font-weight: bold; font-size: 20px;">
                  {{ $t('Team') }}: {{ store.teamName }}
                </h5>
                <button
                  class="button"
                  style="border-radius: 6px;"
                  type="button"
                  :disabled="store.length == 0"
                  @click="toggleEmployees(store.teamNameId)"
                >
                  {{ isStoreActive(store.teamNameId) ? $t('Hide') : $t('Show') }}</button>
              </div>
              <ul
                class="ul-order"
                style="margin-top: 20px;"
              >
                <div
                  v-if="getTeamVacationOverview.length !== 0"
                  v-show="isStoreActive(store.teamNameId)"
                  class="order-header"
                >

                  <p
                    v-b-tooltip.focus
                    href="#"
                    tabindex="0"
                    :title="$t('ExchangeEmployeesFullName')"
                    style="width: 15%; margin-bottom: 0px;text-align: center;"
                  >
                    {{ $t('FullName') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('Vacation')"
                  >
                    {{ $t('Vacation') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('Appoitment')"
                  >
                    {{ $t('Appoitment') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 10%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('PaidLeave')"
                  >
                    {{ $t('PaidLeave') }}
                  </p>
                  <p
                    v-b-tooltip.click
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew46')"
                  >
                    {{ $t('AddNew46') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew23')"
                  >
                    {{ $t('AddNew23') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew22')"
                  >
                    {{ $t('AddNew22') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew21')"
                  >
                    {{ $t('AddNew21') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew20')"
                  >
                    {{ $t('AddNew20') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew19')"
                  >
                    {{ $t('AddNew19') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew9')"
                  >
                    {{ $t('AddNew9') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('RD')"
                  >
                    {{ $t('RD') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    :title="$t('AddNew10')"
                  >
                    {{ $t('AddNew10') }}
                  </p>
                  <p
                    v-b-tooltip.focus
                    style="width: 20%; margin-bottom: 0px; text-align: center;"
                    href="#"
                    tabindex="0"
                    title="Total"
                  >
                    {{ $t('Total') }}
                  </p>
                </div>
                <li
                  v-for="itemd in getTeamVacationOverview"
                  v-show="isStoreActive(store.teamNameId)"
                  :key="itemd.id"
                >
                  <div class="order-list">
                    <div
                      class="order-card"
                      style="display: flex; align-items: center"
                    >
                      <p style="width: 15%; text-align: left;">
                        {{ itemd.fullName }}
                      </p>
                      <p style="width: 13%; text-align: left; padding-left: 15px;">
                        {{ itemd.vacation }}
                      </p>
                      <p style="width: 5%; text-align: center;">
                        {{ itemd.appointment }}
                      </p>
                      <p style="width: 13%; text-align: center;">
                        {{ itemd.paidLeave }}
                      </p>
                      <p style="width: 10%; text-align: center;">
                        {{ itemd.deathCase }}
                      </p>
                      <p style="width: 15%; text-align: center; padding-left: 20px;">
                        {{ itemd.maternity }}
                      </p>
                      <p style="width: 15%; text-align: center;">
                        {{ itemd.specialLeave }}
                      </p>
                      <p style="width: 15%; text-align: center;">
                        {{ itemd.education }}
                      </p>
                      <p style="width: 15%; text-align: center;">
                        {{ itemd.accidentLeave }}
                      </p>
                      <p style="width: 15%; text-align: center;">
                        {{ itemd.sickLeave }}
                      </p>
                      <p style="width: 15%; text-align: center;">
                        {{ itemd.totalCancelled }}
                      </p>
                      <p style="width: 15%; text-align: center;">
                        {{ itemd.totalRemaining }}
                      </p>
                      <p style="width: 15%; text-align: center;">
                        {{ itemd.totalSpent }}
                      </p>
                      <p style="width: 15%; text-align: center;">
                        {{ itemd.total }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div></template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VBTooltip } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      activeStores: [], // Array to store the IDs of active stores
      yearsArray: [],
      year: new Date().getFullYear(), // Set the initial year to the current year
    }
  },
  computed: {
    ...mapGetters(['getStoresForDropdown', 'getTeamVacationOverview', 'getTeamsNames', 'getTeamVacationOverview']),
  },
  watch: {
    year() {
      this.fetchEmployees();
    },
  },
  mounted() {
    // this.changeLoadingtoTrue(false);


    this.teamNames({
      // status: true,
      // storeName: null,
      pageNumber: 1,
      pageSize: 10,
    }).then(() => {
      // this.changeLoadingtoTrue(true);
    });
    // this.changeLoadingtoTrue(false);


    const currentYear = new Date().getFullYear();

    // Create an array with the last year, current year, and next 5 years
    for (let i = 0; i <= 5; i++) {
      this.yearsArray.push(currentYear - i);
    }

    // Set the selected year to the current year
    this.year = currentYear;

    // Fetch employees for the active stores when the component is mounted
    this.fetchEmployees();
  },
  methods: {
    ...mapActions(['teamNames', 'stores_for_Dropdown', 'teamVacationOverview', 'changeLoadingtoTrue', 'reset_teamVacationOverview']),
    // toggleEmployees(teamNameId) {
    //   if (this.isStoreActive(teamNameId)) {
    //     this.activeStores = this.activeStores.filter((id) => { return id !== teamNameId }); // Remove the store from the activeStores array
    //   } else {
    //     this.activeStores.push(teamNameId); // Add the store to the activeStores array
    //   }
    //   this.fetchEmployees(); // Fetch the employees for the active stores
    // },
    toggleEmployees(teamNameId) {
      if (this.isStoreActive(teamNameId)) {
        // If the clicked store is already active, remove it from the activeStores array
        this.activeStores = this.activeStores.filter((id) => { return id !== teamNameId });
      } else {
        // If the clicked store is not active, set the activeStores array to contain only the clicked store
        this.activeStores = [teamNameId];
      }
      this.reset_teamVacationOverview();
      // Fetch the employees for the active stores
      this.fetchEmployees();
    },
    isStoreActive(teamNameId) {
      return this.activeStores.includes(teamNameId); // Check if a store is active
    },
    fetchEmployees() {
      // Fetch employees for the active stores
      this.activeStores.forEach(async (teamNameId) => {
        await this.teamVacationOverview({
          teamNameId,
          year: this.year,
        });
      });
    },
    handleInput() {
      this.year = Math.floor(this.year);

      if (this.year < 1000) {
        this.year = 1000;
      } else if (this.year > 9999) {
        this.year = 9999;
      }
    },
  },
}
</script>


  <style scoped>
  .hr{
    background-color: grey;
  min-width: 201px;
  height: 1px;
  /* UI Properties */
  border: 0.5px solid #A5A5A5;
  opacity: 0.34;
  }
  .material-info:last-child {
    margin-right: 0 !important;
  }
  .pm-li {
    cursor:pointer;
    list-style: none;
    background: #ffffff;
    overflow-y: auto;
    border-radius: 8px;
    border: 1px solid lightgray;
    padding: 12px 10px;
    margin-top: 20px;
  }
  .pm-li h5 {
    margin-bottom: 0;
  }
  .ul-order {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    cursor:default;
  }
  .ul-order li {
    background: #ffffff;
    overflow-y: auto;

    border-radius: 8px;
    border: 1px solid transparent;
    box-shadow: none;
    padding: 6px 2px 6px 10px;
    margin-top: 15px;
    color:#262E6C ; font-weight: 400;font-size: 15px;
  }
  .ul-order ul {
    padding-left: 5px;
  }

  .order-card {
    cursor:default;
    display: flex;
  }

  .order-card p {
    margin-right: 20px;
    color:black ; font-weight: 500;font-size: 15px;
    word-break: break-word;
  }
  .stage-card {
    background: #ffffff;
    border-radius: 4px;
    /* border: 1px solid transparent; */
    box-shadow: none;
    padding: 10px;
    margin-bottom: 15px;
    margin-right: 10px;
    cursor: pointer;
  }
  .stage-card:hover {
   background: #e1ffe1;
  }
  .stage-card.active {
    background: #FF274F !important;
    color: #f4f1ed;
    border-color: transparent;
  }
  .stage-card.activee {
    background-color: grey;
    opacity: 0.7;
    color: #b6ffb6;
    border-top: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, #15E582  70% , #424242 );
  }
  .stage-card.failed {
    background: #FF274F !important;
    opacity: 0.7;
    color: #ffffff;
    border-top:2px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, #e51515  70% , #424242 );
  }
  .order-header{
    display: flex;
    font: normal normal bold 15px/19px;
    font-weight: bold;
    font-size: 15px;
  }
  .order-header{
    color:#262E6C ; font-weight: bold;font-size: 15px;
  }

  .VacSection{
    padding: 10px 25px 15px 120px;
    background-color: transparent
  }

   @media screen and (max-width: 1190px) {
    .main {
      padding: 116px 0.75rem 0 0.75rem !important;
      /* justify-content: center; */
    }
   }

  @media screen and (max-width: 1041px) {
      .VacSection{
        padding: 10px 15px 15px 15px;
      }
    }

  @media screen and (max-width: 1500px){
    .VacSection{
        padding: 10px 35px 15px 100px;
      }
    }

    @media screen and (min-width: 1000px) and (max-width: 1200px) {
      .VacSection{
        padding: 10px 30px 15px 30px;
      }
    }
  </style>
